// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10579(74ed6f9e53)-C19/09/2024-14:08:50-B19/09/2024-14:15:40' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10579(74ed6f9e53)-C19/09/2024-14:08:50-B19/09/2024-14:15:40",
  branch: "master",
  latestTag: "6.1",
  revCount: "10579",
  shortHash: "74ed6f9e53",
  longHash: "74ed6f9e53ee7221cecf3abb94e076f37580a61d",
  dateCommit: "19/09/2024-14:08:50",
  dateBuild: "19/09/2024-14:15:40",
  buildType: "Ansible",
  } ;
