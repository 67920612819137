import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {PrintMenuService} from "../../../../core/services/gestionmenus/print-menu.service";
import {
  PreparationConditionnementService
} from "../../../../core/services/conditionnements/preparation-conditionnement.service";
import {BonAllotGroupingOrder} from "../../../../core/enums/bon-allotissement-grouping-enum";

@Component({
  selector: 'yo-bon-allotissement',
  templateUrl: './bon-allotissement.component.html',
  styleUrls: ['./bon-allotissement.component.scss']
})

export class BonAllotissementComponent implements OnInit, OnDestroy {

  @Input() public idPlanProduction: number;
  displayDialog: boolean = false;
  defaultOrder: boolean = true;

  constructor(
    private printMenuService: PrintMenuService,
    private pcSvc: PreparationConditionnementService) {
  }

  ngOnInit(): void {
    this.printMenuService.displayDialogPrintBonAllotissement$.subscribe(() => {
      this.displayDialog = true;
      this.defaultOrder = true;
    });
  }

  ngOnDestroy(): void {
    this.displayDialog = false;
  }

  downloadBonAllotissement = (): void => {
    const grouping: BonAllotGroupingOrder = this.defaultOrder ? BonAllotGroupingOrder.TOURNEE_PLAT_PLC : BonAllotGroupingOrder.PLAT_TOURNEE_PLC;
    this.pcSvc.printBonAllotissement(this.idPlanProduction, grouping);
    this.displayDialog = false;
  };

  closeDialog = () => {
    this.displayDialog = false;
    this.defaultOrder = true;
  }

}
