<dx-popup
  [showTitle]="true"
  title="Impression du bon d'allotissement"
  [(visible)]="displayDialog"
  [width]="400"
  [height]="'auto'"
  [maxHeight]="800"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>

  <div class="print-order">
      Ordre d'impression
      <dx-switch
        [(ngModel)]="defaultOrder"
        [value]="true"
        switchedOnText="Tournée / Plat / Plc"
        switchedOffText="Plat / Tournée / Plc"
        width="200px"
        height="60px"
      >
      </dx-switch>
  </div>
  <div class="custom-button-container-right">
    <yo-button label="Imprimer" iconClass="fas fa-print"
               (onClick)="downloadBonAllotissement()">
    </yo-button>
    <yo-button buttonClass="cta-inner-delete" iconClass="fas fa-times" (click)="closeDialog()">
    </yo-button>
  </div>

</dx-popup>
